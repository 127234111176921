import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet'
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme'
import aphroditeInterface from 'react-with-styles-interface-aphrodite'

import apptourwhite from '@Common/Resources/Images/AppTourWhite.png'
import LogoGattOriz from '@Common/Resources/Images/LogoGattOriz.png'
import GattinoniLogoBT from '@Common/Resources/Images/GattinoniLogoBT.png'
import RobinturLogo from '@Common/Resources/Images/RobinturLogo.png'

export const DOMAINS = [
  {
    host: 'devstaging.apptour.travel',
    logo: apptourwhite,
    theme: 1
  },
  {
    host: 'devprod.apptour.travel',
    logo: apptourwhite,
    theme: 1
  },
  {
    host: 'admin.apptour.travel',
    logo: apptourwhite,
    theme: 1
  },
  {
    host: 'devstaging.gattinoni.apptour.travel',
    logo: LogoGattOriz,
    theme: 6
  },
  {
    host: 'app.gattinonimondodivacanze.it',
    logo: LogoGattOriz,
    theme: 6
  },
  {
    host: /* ' localhost */ '',
    logo: GattinoniLogoBT,
    theme: 7
  },
  {
    host: 'localhost',
    logo: apptourwhite,
    theme: 1
  },
  {
    host: 'preventivi.robintur.it',
    logo: RobinturLogo,
    theme: 1
  }
]

export const getHost = () => {
  const url = window.location.hostname
  const domainsArray = DOMAINS.filter((el) => el.host === url)
  if (!domainsArray.length) return [DOMAINS[5]]
  return DOMAINS.filter((el) => el.host === url)
}

export const registerCalendarTheme = () => {
  ThemedStyleSheet.registerInterface(aphroditeInterface)
  ThemedStyleSheet.registerTheme({
    reactDates: {
      ...DefaultTheme.reactDates,
      color: {
        ...DefaultTheme.reactDates.color,
        highlighted: {
          backgroundColor: '#ffe8bc',
          backgroundColor_active: '#ffce71',
          backgroundColor_hover: '#ffce71',
          color: '#484848',
          color_active: '#484848',
          color_hover: '#484848'
        },
        hoveredSpan: {
          backgroundColor: '#b2f1ec',
          backgroundColor_active: '#80e8e0',
          backgroundColor_hover: '#b2f1ec',
          borderColor: '#80e8e0',
          borderColor_active: '#80e8e0',
          borderColor_hover: '#80e8e0',
          color: '#007a87',
          color_active: '#007a87',
          color_hover: '#007a87'
        }
      }
    }
  })
}

export const Themes = {
  colors: {
    translucent: 'rgba(231, 231, 231, 0.8)',
    purple: 'rgb(50, 50, 78)',
    white: 'rgb(255, 255, 255)'
  },
  registerCalendarTheme
}
